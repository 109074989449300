import { mapState } from 'vuex';

export default {
	computed: {
		...mapState({
			countries: store => store.app.countries
		})
	},
	methods: {
		getCountry(slug) {
			return this.countries.find(c => c.slug === slug);
		}
	}
};
